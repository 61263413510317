dmx.Component('bs5-offcanvas', {
    initialData: {
        open: false
    },

    attributes: {
        show: {
            type: Boolean,
            default: false
        },

        nobackdrop: {
            type: Boolean,
            default: false
        },

        nokeyboard: {
            type: Boolean,
            default: false
        },

        scroll: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            var self = this;
            requestAnimationFrame(function() {
                self.instance.toggle();
            });
        },

        show: function() {
            var self = this;
            requestAnimationFrame(function() {
                self.instance.show();
            });
        },

        hide: function() {
            var self = this;
            requestAnimationFrame(function() {
                self.instance.hide();
            });
        }
    },

    events: {
        show: Event,
        shown: Event,
        hide: Event,
        hidden: Event
    },

    onShown: function() {
        this.set('open', true);
    },

    onHidden: function() {
        this.set('open', false);
    },

    render: function(node) {
        this.$node = node;
        this.$parse();

        this.$node.addEventListener('show.bs.offcanvas', this.dispatchEvent.bind(this, 'show'));
        this.$node.addEventListener('shown.bs.offcanvas', this.dispatchEvent.bind(this, 'shown'));
        this.$node.addEventListener('hide.bs.offcanvas', this.dispatchEvent.bind(this, 'hide'));
        this.$node.addEventListener('hidden.bs.offcanvas', this.dispatchEvent.bind(this, 'hidden'));

        this.$node.addEventListener('shown.bs.offcanvas', this.onShown.bind(this));
        this.$node.addEventListener('hidden.bs.offcanvas', this.onHidden.bind(this));

        this.instance = new bootstrap.Offcanvas(this.$node, {
            backdrop: !this.props.nobackdrop,
            keyboard: !this.props.nokeyboard,
            scroll: this.props.scroll
        });

        this.update({});
    },

    update: function(props) {
        if (props.show != this.props.show) {
            this.$node.classList.toggle('show', this.props.show);
            this.set('open', this.props.show);
        }
    }
});